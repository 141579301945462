@media screen and (max-width: 820px) {
    .home {
        flex: 1;
        position: relative;
        left: 10px;
    }

    .feedoil-nav {
        padding: 0 1rem !important;
    }

    .feedoil-nav-links.hamburger-open {
        width: 50%;
        // height: 50vh;
        display: block;
        margin-top: 5px;
        margin-bottom: 5px;
        position: absolute;
        top: 0;
        right: 0;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(10px);
    }

    .feedoil-nav-links.hamburger-open>ul {
        display: block !important;
        margin: 2rem;
        margin-right: 5rem;
        color: #333;
    }

    .hamburger:active {
        color: #333;
    }

    .feedoil-nav-links {
        display: none;
    }

    .feedoil-language-chooser {
        margin-top: -25%;
        z-index: -1;
    }
}

@media screen and (min-width: 820px) {
    .hamburger {
        display: none;
    }

    .feedoil-language-chooser {
        margin-top: -5px;
        margin-left: 1rem;
    }
}

.feedoil-nav .home {
    display: flex;
}

.feedoil-nav .logo {
    margin-right: 1rem;
}

.feedoil-nav .site-title {
    font-size: 2rem;
    align-items: center !important;
}

.feedoil-nav .site-title:hover {
    color: white;
}

.feedoil-nav .home {
    cursor: pointer;
}

.feedoil-nav {
    background-color: transparent;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    width: 98%;
    padding: 0 5rem;
    top: 0;
    height: 90px;
    position: fixed;
    z-index: 1;
    transition: 0.5s;
    border-radius: 10px;
    margin-left: 1%;
    margin-right: 1%;
}

.feedoil-nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem
}

.feedoil-nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: stretch;
    padding: 0.25rem;
    cursor: pointer;
}

.feedoil-nav a:hover {
    color: rgba(148, 193, 31, 1);
}

.feedoil-nav li:has(a.active) {
    background-color: #94c11f;
}

.feedoil-nav a.active {
    color: white;
}

.feedoil-nav.sticky {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    height: 75px;
    z-index: 1;
    background-color: #333;
    margin-top: 2px;
}

.feedoil-language-chooser {
    width: min-content;
    height: 0px;
}

.feedoil-language-chooser>span {
    margin-left: 10px;
}