.hero-container {
  background-color: black;
}

.hero-section {
  height: 100vh;
  width: 100%;
  display: flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  background-size: cover;
  background-position: center;
  position: relative;
  transition: background-image 2s ease-in-out;
  -webkit-animation: 0.25s ease-in 0s normal forwards 1 fadein;
  animation: 0.4s ease-in 0s normal forwards 1 fadein;
}

.hero-section>h1 {
  color: #fff;
  font-size: 100px;
  margin-top: -100px;
  font-weight: 500;
  text-transform: capitalize;
  border-bottom: rgba(255, 255, 255, 0.3) 1px solid;
  width: 70%;
  text-align: center;
  -webkit-animation: 0.4s ease-in 0s normal forwards 1 fadein;
  animation: 0.4s ease-in 0s normal forwards 1 fadein;
  opacity: 0;
}

.hero-section>p {
  margin-top: 20px;
  color: #fff;
  font-size: 32px;
  font-weight: 500;
  transition-delay: 1;
  opacity: 0;
  -webkit-animation: 0.25s ease-in 0.4s normal forwards 1 fadein;
  animation: 0.25s ease-in 0.4s normal forwards 1 fadein;
}

.hero-section>h3 {
  color: #fff;
  margin-top: 30px;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  width: 70%;
  opacity: 0;
  -webkit-animation: 0.25s ease-in 0.6s normal forwards 1 fadein;
  animation: 0.25s ease-in 0.6s normal forwards 1 fadein;
}

.hero-section>a {
  width: 70px;
  height: 70px;
  margin-top: 70px;
  padding: 14px;
  border: 2px solid #fff;
  border-radius: 100% !important;
  font-size: 40px;
  color: #94c11f;
  background: 0 0;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  text-align: center;
  cursor: pointer;
  opacity: 0;
  -webkit-animation: 0.5s ease-in 1s normal forwards 1 fadeInDown;
  animation: 0.5s ease-in 1s normal forwards 1 fadeInDown;
}

.hero-section>a:hover {
  outline: 0;
  color: #fff;
  background: rgba(255, 255, 255, .1);
}


@media screen and (max-width: 960px) {
  .hero-section>h1 {
    font-size: 70px;
    margin-top: -150px;
  }

  .hero-section>p {
    font-size: 26px;
  }
}

@media only screen and (hover: none) and (pointer: coarse) and (orientation:landscape) {
  .hero-section>h1 {
    margin-top: 0px;
    font-size: 50px;
  }

  .hero-section>p {
    font-size: 20px;
  }

  .hero-section>h3 {
    font-size: 14px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }

  .hero-section>a {
    margin-top: 10px;
  }
}

@media only screen and (hover: none) and (pointer: coarse) and (orientation:landscape) and (max-width: 768px)  {
  .hero-section>h1 {
    margin-top: -20px !important;
    font-size: 40px !important;
  }

  .hero-section>p {
    font-size: 20px !important;
  }

  .hero-section>h3 {
    font-size: 12px !important;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }

  .hero-section>a {
    margin-top: 10px;
    height: 50px;
    width: 50px;
    font-size: 30px;
    padding: 8px;
  }
}


@media screen and (max-width: 768px) {
  .hero-section>h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-section>p {
    font-size: 20px;
  }

  .hero-section>h3 {
    font-size: 14px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}