.feedoil-cardcarousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.feedoil-cardcarousel-card-container {
  position: relative;
  width: 30rem;
  height: 25rem;
  margin-top: 2rem;
}

.feedoil-cardcarousel-card {
  margin-top: 2rem;
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 1rem;
  border: 2px solid #80a81b;
  padding: 20px;
  min-width: 300px;
}

.feedoil-cardcarousel-card h2 {
  border-bottom: 1px solid #80a81b;
  text-align: center;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
}

.feedoil-cardcarousel-card p {
  text-align: center;
  font-size: 1rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
}

.feedoil-cardcarousel-prevCard {
  left: calc(0% - 35rem);
  opacity: 0;
  transition: all 1s;
}

.feedoil-cardcarousel-activeCard {
  left: 50%;
  transform: translateX(-50%);
  transition: all 1s;
}

.feedoil-cardcarousel-nextCard {
  left: 100%;
  transform: translateX(calc(-100% + 35rem));
  opacity: 0;
  transition: all 1s;
}

.feedoil-cardcarousel-btn {
  font-size: 20px;
  z-index: 999;
  margin: 20px;
  padding: 10px;
  margin-top: 15vh;
}

.feedoil-cardcarousel-btn:hover {
  cursor: pointer;
  color: #80a81b;
}

.feedoil-cardcarousel-paginator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: -2rem;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  max-width: 30rem;
  height: 2rem;
}

.feedoil-cardcarousel-hr {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #80a81b;
}

.feedoil-cardcarousel-dot {
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: #80a81b;
  z-index: 1;
  transition: all 1s;
}

.feedoil-cardcarousel-dot:hover {
  cursor: pointer;
}

.feedoil-cardcarousel-dot.active {
  box-shadow: 0 0 0 4px #80a81b;
  background-color: white;
}

.feedoil-link {
  text-decoration: none;
  color: #80a81b;
  text-align: center;
}

.feedoil-link.container {
  text-align: center;
}

@media screen and (max-width: 780px) {
  .feedoil-cardcarousel-container {
    width: 100%;
    height: 40rem;
    overflow: hidden;
  }

  .feedoil-cardcarousel-card-container {
    height: 30rem;
    transition: all 1s;
  }
}

@media screen and (max-width: 510px) {
  .feedoil-cardcarousel-card p {
    text-align: center;
    font-size: 0.9rem;
    transition: all 1s;
  }
}

@media screen and (max-width: 425px) {
  .feedoil-cardcarousel-btn {
    font-size: 20px;
    z-index: 999;
    margin: 0px;
    padding: 10px;
    margin-top: 15vh;
  }
}
